import gql from "graphql-tag";

export const GET_BUSINESSES = gql`
    query GetBusinesses($name: String)@cached(ttl: 300) {
        businesses: business(
            where: {
                closed: { _eq: false }
                approvalState: { _eq: APPROVED }
                name: { _ilike: $name }
            }
            order_by: { name: asc }
        ) {
            id
            name
            logo {
                id
                imageUrl
                imagePath
            }
            approvalState
            categories {
                category {
                    name
                }
            }
        }
    }
`;

export const GET_ALL_BUSINESSES = gql`
    query GetBusinesses($name: String)@cached(ttl: 300) {
        businesses: business(
            where: { closed: { _eq: false }, name: { _ilike: $name } }
            order_by: { name: asc }
        ) {
            id
            name
            logo {
                id
                imageUrl
                imagePath
            }
            approvalState
            categories {
                category {
                    name
                }
            }
        }
    }
`;

export const GET_ALL_LOCATION_MARKERS = gql`
    query GetLocationMarkers {
        locations: location(
            where: { closed: { _eq: false }, business: { closed: { _eq: false } } }
        ) {
            name
            business {
                id
                name
            }
            address {
                id
                position
            }
        }
    }
`;
