<template>
  <gmap
      ref="mapRef"
      :center="center"
      :zoom="11"
      map-type-id="terrain"
      style="max-width:100vw; height: 92vh"

  >
    <gmarker
        v-for="(m, index) in markers"
        :key="index"
        :clickable="true"
        :draggable="false"
        :position="m.position"
        :title="m.title"
        @click="toggleInfo(m, index)"
    >
      <g-info-window
          :content="m.infoContent"
          :opened="m.infoOpened"
          :options="m.infoOptions"
          :position="m.infoPosition"
          @closeclick="m.infoOpened=false"/>
    </gmarker>
  </gmap>
</template>
<script>
import {GET_ALL_LOCATION_MARKERS} from "@/modules/businesses/graph/queries";

export default {
  name: "Map",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Map',
  },
  data() {
    return {
      center: {lat: 38.57315542550308, lng: -121.48107891498117},
      markers: []
    }
  },
  methods: {
    toggleInfo(m, index) {
      this.markers.forEach(item => item.infoOpened = false)
      this.markers[index].infoOpened = !m.infoOpened
    },
  },
  apollo: {
    locations: {
      fetchPolicy: "cache-and-network",
      query: GET_ALL_LOCATION_MARKERS,
      result({data}) {
        if (data?.locations?.length > 0) {
          this.markers = data.locations.map((location) => {
            if (location?.address?.position) {
              return {
                name: `${location?.business?.name} (${location?.name})`,
                position: {...location.address.position},
                infoPosition: null,
                infoContent: null,
                infoOpened: false,
                infoCurrentKey: null,
                infoOptions: {
                  pixelOffset: {
                    width: 0,
                    height: 0
                  },
                  content:
                      '<div id="content">' +
                      '<div id="siteNotice">' +
                      '</div>' +
                      `<h1 id="firstHeading" class="firstHeading">${location?.business?.name} </h1>` +
                      '<div id="bodyContent">' +
                      `<p>(${location?.name})</p>` +
                      '</div>' +
                      '</div>'
                }

              }
            }
          });
        }
      }
    },
  },
}
</script>